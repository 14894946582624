// tslint:disable:no-submodule-imports
import * as Sentry from '@sentry/browser';
import { Action } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';

import { getCompanies } from '../../actions/company.action';
import { genericError } from '../../actions/error.action';
import { LoginActionTypes, loginFailed, loginSuccess } from '../../actions/login.action';
import { LoginAction, LoginSuccessAction } from './../../actions/login.action';

const url = `${process.env.REACT_APP_API_URL}/v1/user/login`;
const timeoutValue = Number(process.env.REACT_APP_DEFAULT_TIMEOUT);

/**
 * Logins a user in the backend.
 */
export const loginUserEpic = (action$: ActionsObservable<any>, store, { ajax, scheduler }): Observable<Action> =>
  action$.pipe(
    ofType<LoginAction>(LoginActionTypes.LOGIN_ACTION),
    switchMap((action: LoginAction) =>
      ajax({
        url,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: { email: action.payload.username, password: action.payload.password },
      }).pipe(
        timeout(timeoutValue, scheduler),
        map((response: AjaxResponse) => loginSuccess(response.response.token)),
        catchError((error: Error) => {
          const errorMsg = 'Error to login user';
          Sentry.captureEvent({
            message: errorMsg,
            level: Sentry.Severity.Warning,
            extra: {
              error,
            },
          });
          console.warn(errorMsg, error);
          return of(loginFailed(error));
        }),
      ),
    ),
  );

/**
 * Immediately fire the Get Companies Action when the login is successful
 */
export const loginSuccessEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType<LoginSuccessAction>(LoginActionTypes.LOGIN_SUCCESS_ACTION),
    map(() => getCompanies()),
    catchError((error) => {
      Sentry.captureException(error);
      return of(genericError(error));
    }),
  );
