// tslint:disable-next-line:no-submodule-imports
import { AjaxError } from 'rxjs/ajax';

import { REQ_STATUS_PROCESSING, REQ_STATUS_SUCCESS, REQ_STATUS_UNDEFINED } from '../../common/request-status';
import { CompanyActions, CompanyActionTypes } from './../../actions/company.action';
import { Company } from './../../common/company.model';
import { REQ_STATUS_FAIL } from './../../common/request-status';

export interface CompanyState {
  selectedCompany: Company;
  companies: Company[];
  error?: Error;
  errorMessage: string;
  status: number;
}

export const initialState: CompanyState = {
  selectedCompany: new Company(),
  companies: [],
  error: undefined,
  errorMessage: '',
  status: REQ_STATUS_UNDEFINED,
};

export function companyReducer(state: CompanyState = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.GET_COMPANIES_ACTION:
      return { ...state, status: REQ_STATUS_PROCESSING, error: undefined, companies: [] };

    case CompanyActionTypes.GET_COMPANIES_SUCCESS_ACTION:
      return { ...state, status: REQ_STATUS_SUCCESS, error: undefined, companies: action.payload.companies };

    case CompanyActionTypes.GET_COMPANIES_FAIL_ACTION:
      const errorMessage = handleErrorMessage(action.payload.error);
      return { ...state, status: REQ_STATUS_FAIL, error: action.payload.error, errorMessage, companies: [] };

    case CompanyActionTypes.SELECT_COMPANY_ACTION:
    case CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE_SUCCESS:
      return { ...state, selectedCompany: action.payload.company };

    default:
      return state;
  }
}

/**
 * Display a friendlier error message.
 */
function handleErrorMessage(error: Error): string {
  let errorMessage = 'Erro ao realizar ao obter as companies, por favor tente novamente mais tarde.';

  if (error.name === 'AjaxError') {
    const ajaxError = error as AjaxError;
    if (ajaxError.response.error === 'Could not authenticate user') {
      errorMessage = 'Erro ao autenticar o usuário. Recarregue a página e tente novamente';
    }
  }
  else if (error.name === 'TimeoutError') {
    errorMessage = 'Tempo limite atingido, por favor tente novamente';
  }

  return errorMessage;
}
