import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { loadCompanyFromLocalStorage } from './actions/company.action';
import { LoginErrorBoundary } from './error-boundaries/login-error-boundary/login-error-boundary';
import { SGMErrorBoundary } from './error-boundaries/sgm-error-boundary/sgm-error-boundary';
import configureStore from './reducers/store';
import { configSentry } from './sentry';
import * as serviceWorker from './serviceWorker';

configSentry();
export const store = configureStore();
store.dispatch(loadCompanyFromLocalStorage());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path='/' exact={true} component={SGMErrorBoundary} />
        <Route path='/login' component={LoginErrorBoundary} />
        <Route path='*' component={SGMErrorBoundary} />
      </Switch>
    </BrowserRouter>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
