export class Company {
  public numericalId: number;
  public name: string;
  public brand: string;
  public posToken: string;
  public brandName: string;
  public id: string;

  constructor() {
    this.numericalId = 0;
    this.name = '';
    this.brand = '';
    this.posToken = '';
    this.brandName = '';
    this.id = '';
  }
}
