import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { login } from '../../actions/login.action';
import LoginForm from '../../components/login-form/login-form';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLoginUser: bindActionCreators(login, dispatch),
});

const LoginFormContainer = connect(null, mapDispatchToProps)(LoginForm);
export default LoginFormContainer;
