import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { selectCompany } from '../../actions/company.action';
import CompanySelector from '../../components/select-company/company-selector';
import { State } from '../../reducers/store';

const mapStateToProps = (state: State) => ({
  companies: state.companyState.companies,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSelectCompany: bindActionCreators(selectCompany, dispatch),
});

/**
 * Container for the CompanySelector view.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CompanySelector);
