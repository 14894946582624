import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { sendTelephoneNumber, telephoneAppend, telephoneBackspace } from '../../actions/sgm.action';
import { Keyboard } from '../../components/keyboard/keyboard';

const mapDispathToProps = (dispatch: Dispatch) => ({
  onKeyPress: bindActionCreators(telephoneAppend, dispatch),
  onSubmitPress: bindActionCreators(sendTelephoneNumber, dispatch),
  onBackspacePress: bindActionCreators(telephoneBackspace, dispatch),
});

const KeyboardContainer = connect(undefined, mapDispathToProps)(Keyboard);
export default KeyboardContainer;
