import './sgm.scss';

import { faEllipsisV, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Particles, { IParticlesParams } from 'react-particles-js';
import { Link } from 'react-router-dom';

import {
  REQ_STATUS_FAIL,
  REQ_STATUS_PROCESSING,
  REQ_STATUS_SUCCESS,
  REQ_STATUS_UNDEFINED,
} from '../../common/request-status';
import KeyboardContainer from '../../containers/keyboard-container/keyboard-container';
import { PhoneInput } from '../phone-input/phone-input';

export interface SGMProps {
  telephone: string;
  message: string;
  sendStatus: number;
}

export default class SGM extends React.Component<SGMProps> {
  private particlesParams: IParticlesParams = {
    particles: {
      number: {
          value: 160,
          density: {
              enable: false,
          },
      },
      size: {
          value: 3,
          random: true,
          anim: {
              speed: 4,
              size_min: 0.3,
          },
      },
      line_linked: {
          enable: false,
      },
      move: {
          random: true,
          speed: 1,
          direction: 'top',
          out_mode: 'out',
      },
    },
  };

  constructor(props: SGMProps) {
    super(props);
  }

  public render() {
    return (
      <div className='sgm d-flex flex-column align-items-center justify-content-center'>
        <div className='sgm-content p-3 d-flex flex-column align-items-center justify-content-center flex-fill'>
          <Link to='/login' className='config-button'>
            <FontAwesomeIcon icon={faEllipsisV} size='lg' />
          </Link>

          <div className='d-flex align-items-center flex-fill text-center'>
            {this.handleCompanyState()}
          </div>

          <div>
            <img className='onyo-logo' src={'logo_onyo-05.png'} />
          </div>
        </div>

        <Particles className='particles' params={this.particlesParams} />
      </div>
    );
  }

  private handleCompanyState(): JSX.Element {
    switch (this.props.sendStatus) {
      case REQ_STATUS_PROCESSING:
        return (
          <div className='menu-icon sgm-text p-2'>
            <FontAwesomeIcon icon={faSpinner} size={'4x'} pulse={true} />
          </div>
        );

      case REQ_STATUS_SUCCESS:
      case REQ_STATUS_FAIL:
        return (
          <div className='feedback-message p-2 sgm-text'>
            <h3 className='m-0'><strong>{this.props.message}</strong></h3>
          </div>
        );

      case REQ_STATUS_UNDEFINED:
      default:
        return (
          <div className='row align-items-center'>
            <div className='welcome-message sgm-text col-7'>
              <h3 className='m-0'><strong>{this.props.message}</strong></h3>
            </div>
            <div className='phone-input col-5 d-flex flex-column align-items-center'>
              <div>
                <PhoneInput telephone={this.props.telephone} />
              </div>
              <div>
                <KeyboardContainer />
              </div>
            </div>
          </div>
        );
    }
  }
}
