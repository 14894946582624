import * as React from 'react';
import LoginContainer from '../../containers/login-container/login-container';
import ErrorBoundary from '../error-boundary/error-boundary';

export function LoginErrorBoundary() {
    return (
      <ErrorBoundary
        // tslint:disable-next-line:max-line-length
        errorMessage='Erro ao carregar o componente de login. Por favor entre em contato com o nosso suporte no telefone: 3003-0548 ou pelo e-mail: ajuda@onyo.com.'
      >
        <LoginContainer />
      </ErrorBoundary>
    );
}
