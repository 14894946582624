// tslint:disable:no-submodule-imports

import { Action, applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { ajax } from 'rxjs/ajax';

import { resetTelephoneFormEpic, sendTelephoneEpic } from '../epics/sgm-epic/sgm.epic';
import { companyEpic, loadCompanyFromLocalStorageEpic, saveCompanyEpic } from './../epics/company-epic/company.epic';
import { loginSuccessEpic, loginUserEpic } from './../epics/login-epic/login.epic';
import * as companyReducer from './company-reducer/company.reducer';
import * as loginReducer from './login-reducer/login.reducer';
import * as sgmReducer from './sgm-reducer/sgm.reducer';

export interface State {
  loginState: loginReducer.LoginState;
  companyState: companyReducer.CompanyState;
  sgmState: sgmReducer.SGMState;
}

export const initialState: State = {
  loginState: loginReducer.initialState,
  companyState: companyReducer.initialState,
  sgmState: sgmReducer.initialState,
};

export const reducers = combineReducers<State>({
  loginState: loginReducer.loginReducer,
  companyState: companyReducer.companyReducer,
  sgmState: sgmReducer.sgmReducer,
});

export default function configureStore(): Store<State, Action> {
  const epicMiddleware = createEpicMiddleware({
    dependencies: { ajax, scheduler: undefined, lStorage: localStorage },
  });

  // Devtools must be removed for production build
  let store;
  if (process.env.REACT_APP_ENV === 'production') {
    store = createStore(
      reducers,
      initialState,
      applyMiddleware(epicMiddleware),
    );
  }
  else {
    store = createStore(
      reducers,
      initialState,
      composeWithDevTools(applyMiddleware(epicMiddleware)),
    );
  }

  const epics = combineEpics(
    loginUserEpic,
    loginSuccessEpic,
    companyEpic,
    saveCompanyEpic,
    loadCompanyFromLocalStorageEpic,
    sendTelephoneEpic,
    resetTelephoneFormEpic,
  );
  epicMiddleware.run(epics);

  return store;
}
