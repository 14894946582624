// tslint:disable:no-submodule-imports
import { AjaxError } from 'rxjs/ajax';

import {
  REQ_STATUS_FAIL,
  REQ_STATUS_PROCESSING,
  REQ_STATUS_SUCCESS,
  REQ_STATUS_UNDEFINED,
} from '../../common/request-status';
import { SGMActions, SGMActionTypes } from './../../actions/sgm.action';

export interface SGMState {
  telephone: string;
  formattedTelephone: string;
  error?: Error;
  message: string;
  status: number;
}

export const initialState: SGMState = {
  telephone: '',
  formattedTelephone: '',
  error: undefined,
  message: 'Ganhe R$10 no primeiro pedido no app Onyo. E na próxima, nem precisa pegar fila!',
  status: REQ_STATUS_UNDEFINED,
};

export function sgmReducer(state: SGMState = initialState, action: SGMActions): SGMState {
  let num: string;
  let formattedNum: string;
  let message: string;

  switch (action.type) {
    case SGMActionTypes.SEND_TELEPHONE_NUMBER_ACTION:
      return { ...state, error: undefined, message: '', status: REQ_STATUS_PROCESSING };

    case SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION:
      return { ...state, message: 'Telefone cadastrado com sucesso. Em breve você receberá um SMS', status: REQ_STATUS_SUCCESS };

    case SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION:
      message = handleErrorMessages(action.payload.error);
      return { ...state, status: REQ_STATUS_FAIL, message, error: action.payload.error };

    case SGMActionTypes.RESET_TELEPHONE_FORM_ACTION:
      return { ...initialState };

    case SGMActionTypes.TELEPHONE_APPEND_ACTION:
      // Allow only 11 digits.
      if (state.telephone.length >= 11) {
        return state;
      }

      num = filterNonNumericalChars(state.telephone.concat(action.payload.num));
      formattedNum = formatCellphone(num);
      return { ...state, telephone: num, formattedTelephone: formattedNum };

    case SGMActionTypes.TELEPHONE_BACKSPACE_ACTION:
      num = state.telephone.slice(0, -1);
      formattedNum = formatCellphone(num);
      return { ...state, telephone: num, formattedTelephone: formattedNum };

    default:
      return state;
  }
}

/**
 * User friendly error messages
 */
function handleErrorMessages(error: Error): string {
  let errorMessage = 'Erro no envio do telefone. Por favor tente novamente.';

  if (error.name === 'AjaxError') {
    const ajaxError = error as AjaxError;
    if (ajaxError.response.error === 'onyo.user.phone-already.exists') {
      errorMessage = 'Esse número de telefone já está cadastrado na Onyo. Por favor utilize outro número.';
    }
    else if (ajaxError.response.error === 'onyo.invalid-data') {
      errorMessage = 'Não há um cupom cadastrado para esse restaurante. Por favor entre em contato com o nosso suporte.';
    }
  }
  else if (error.name === 'TimeoutError') {
    errorMessage = 'Tempo limite atingido, por favor tente novamente';
  }
  else if (error.name === 'no-company-selected') {
    errorMessage = 'Não há uma company selecionada. Por favor selecione uma company ou entre em contato com o nosso suporte.';
  }
  else if (error.name === 'invalid-telephone-number') {
    errorMessage = 'Número de telefone inválido, por favor digite o número completo com DDD: (XX) XXXXX-XXXX';
  }

  return errorMessage;
}

/**
 * Formats a number string into the cellphone format: "(XX) XXXXX-XXXX"
 * Note: all non numeric characters will be filtered out of the input string.
 */
function formatCellphone(cellphoneNumber: string): string {
  // Begin formatting from 3 charactes and above, to make sure we can remove the '()' from the prefix.
  if (cellphoneNumber.length < 3) {
    return cellphoneNumber;
  }

  // Allow only 11 digits.
  if (cellphoneNumber.length > 11) {
    cellphoneNumber = cellphoneNumber.substring(0, 11);
  }

  const ddd = cellphoneNumber.substring(0, 2);
  const prefix = cellphoneNumber.substring(2, 7);
  const sufix = cellphoneNumber.substring(7);

  let formattedValue: string;
  if (sufix === '') {
    formattedValue = `(${ddd}) ${prefix}`;
  }
  else {
    formattedValue = `(${ddd}) ${prefix}-${sufix}`;
  }

  return formattedValue;
}

/**
 * Remove all non numerical chars from string.
 */
function filterNonNumericalChars(str: string): string {
  return str.replace(new RegExp('\\D', 'g'), ''); // get only the numbers from the input string.
}
