import { Action } from 'redux';

export enum GenericErrorTypes {
  GENERIC_ERROR = 'generic-error',
}

/**
 * Used for error handling.
 */
export interface GenericErrorAction extends Action {
  type: GenericErrorTypes.GENERIC_ERROR;
  payload: {
    error: any;
  };
}

export function genericError(error: any): GenericErrorAction {
  return {
    type: GenericErrorTypes.GENERIC_ERROR,
    payload: { error },
  };
}
