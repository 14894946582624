import * as Sentry from '@sentry/browser';
import * as React from 'react';
import './error-boundary.scss';

export interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
  errorInfo: any;
}

export interface ErrorBoundaryProps {
  errorMessage: string;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary'>
          <div className='error-text text-center container pt-2 pb-2'>
            <h4>{this.props.errorMessage}</h4>
            <p>{this.state.error && this.state.error.toString()}</p>
          </div>
        </div>
      );
    }
    else {
      return this.props.children;
    }
  }

  public componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
}
