import './login-view.scss';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { REQ_STATUS_FAIL, REQ_STATUS_PROCESSING, REQ_STATUS_UNDEFINED } from '../../common/request-status';
import CompanySelector from '../../containers/company-container/company-container';
import LoginFormContainer from '../../containers/login-form-container/login-form-container';
import { ConfigError } from '../config-error/config-error';

export interface LoginViewProps {
  loginRequestStatus: number;
  loginErrorMessage: string;
  companyRequestStatus: number;
  companyErrorMessage: string;
  companyName: string;
}

export default class LoginView extends React.Component<LoginViewProps> {
  constructor(props: LoginViewProps) {
    super(props);
  }

  public render() {
    return (
      <div className='login-view container pt-3 pb-3'>
        <div className='text-center'>
          <h4 className='header-text'>Login Onyo</h4>
          <h4 className='header-text'>{this.props.companyName}</h4>
          <hr />
        </div>
        <div>
          {this.handleLoginAndCompanyState()}
        </div>
      </div>
    );
  }

  /**
   * Shows different forms depending on the app state
   */
  private handleLoginAndCompanyState(): JSX.Element {
    if (this.props.loginRequestStatus === REQ_STATUS_UNDEFINED) {
      return (<LoginFormContainer />);
    }
    else if (this.props.loginRequestStatus === REQ_STATUS_PROCESSING || this.props.companyRequestStatus === REQ_STATUS_PROCESSING) {
      return (
        <div className='spinner-icon text-center'>
          <FontAwesomeIcon icon={faSpinner} size={'4x'} pulse={true} />
        </div>
      );
    }
    else if (this.props.loginRequestStatus === REQ_STATUS_FAIL) {
      return (
        <div>
          <LoginFormContainer />
          <ConfigError errorMessage={this.props.loginErrorMessage} />
        </div>
      );
    }
    else if (this.props.companyRequestStatus === REQ_STATUS_FAIL) {
      return (
        <div>
          <LoginFormContainer />
          <ConfigError errorMessage={this.props.companyErrorMessage} />
        </div>
      );
    }
    else {
      return (<CompanySelector />);
    }
  }
}
