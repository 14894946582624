import { faBackspace, faCheck } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';

import { KeyboardKey } from '../keyboard-key/keyboard-key';

export interface KeyboardProps {
  onKeyPress: (value: string) => void;
  onSubmitPress: () => void;
  onBackspacePress: () => void;
}

export function Keyboard(props: KeyboardProps) {
    return (
      <table className='keyboard-grid m-1'>
        <tr>
          <td><KeyboardKey value='1' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='2' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='3' onKeyPress={props.onKeyPress} /></td>
        </tr>
        <tr>
          <td><KeyboardKey value='4' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='5' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='6' onKeyPress={props.onKeyPress} /></td>
        </tr>
        <tr>
          <td><KeyboardKey value='7' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='8' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='9' onKeyPress={props.onKeyPress} /></td>
        </tr>
        <tr>
          <td><KeyboardKey value='' icon={faBackspace} onKeyPress={props.onBackspacePress} /></td>
          <td><KeyboardKey value='0' onKeyPress={props.onKeyPress} /></td>
          <td><KeyboardKey value='' icon={faCheck} onKeyPress={props.onSubmitPress} /></td>
        </tr>
      </table>
    );
}
