import './phone-input.scss';

import React from 'react';
import { Input, InputGroup } from 'reactstrap';

interface IPhoneInputProps {
  telephone: string;
}

export class PhoneInput extends React.Component<IPhoneInputProps> {
  constructor(props: IPhoneInputProps) {
    super(props);
  }

  public render() {
    return (
      <div className='phone-input'>
        <InputGroup>
          <Input
            placeholder='Digite o seu celular'
            type='tel'
            name='cellphoneInput'
            className='cellphone-input'
            value={this.props.telephone}
            required={true}
            disabled={true}
          />
        </InputGroup>
      </div>
    );
  }
}
