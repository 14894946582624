import { Action } from 'redux';

import { Company } from './../common/company.model';

export enum CompanyActionTypes {
  GET_COMPANIES_ACTION = 'get-companies',
  GET_COMPANIES_SUCCESS_ACTION = 'get-companies-success',
  GET_COMPANIES_FAIL_ACTION = 'get-companies-fail',
  SELECT_COMPANY_ACTION = 'select-company',
  SAVE_COMPANY_SUCCESS_ACTION = 'save-company-success',
  LOAD_COMPANY_FROM_LOCAL_STORAGE = 'load-company-from-local-storage',
  LOAD_COMPANY_FROM_LOCAL_STORAGE_SUCCESS = 'load-company-from-local-storage-success',
  LOAD_COMPANY_FROM_LOCAL_STORAGE_FAIL = 'load-company-from-local-storage-fail',
}

/**
 * Retrieve the company list from the backend.
 */
export interface GetCompaniesAction extends Action {
  type: CompanyActionTypes.GET_COMPANIES_ACTION;
}

export function getCompanies(): GetCompaniesAction {
  return {
    type: CompanyActionTypes.GET_COMPANIES_ACTION,
  };
}

export interface GetCompaniesSuccessAction extends Action {
  type: CompanyActionTypes.GET_COMPANIES_SUCCESS_ACTION;
  payload: {
    companies: Company[];
  };
}

export function getCompaniesSuccess(companies: Company[]): GetCompaniesSuccessAction {
  return {
    type: CompanyActionTypes.GET_COMPANIES_SUCCESS_ACTION,
    payload: { companies },
  };
}

export interface GetCompaniesFailAction extends Action {
  type: CompanyActionTypes.GET_COMPANIES_FAIL_ACTION;
  payload: {
    error: Error;
  };
}

export function getCompaniesFail(error: Error): GetCompaniesFailAction {
  return {
    type: CompanyActionTypes.GET_COMPANIES_FAIL_ACTION,
    payload: { error },
  };
}

export interface SelectCompanyAction extends Action {
  type: CompanyActionTypes.SELECT_COMPANY_ACTION;
  payload: {
    company: Company;
  };
}

/**
 * Set a company as the selected company and stores it in the local storage
 */
export function selectCompany(company: Company): SelectCompanyAction {
  return {
    type: CompanyActionTypes.SELECT_COMPANY_ACTION,
    payload: { company },
  };
}

/**
 * Is executed when the selected company is successfully saved in the local storage
 */
export interface SaveCompanySuccessAction {
  type: CompanyActionTypes.SAVE_COMPANY_SUCCESS_ACTION;
}

export function saveCompanySuccess(): SaveCompanySuccessAction {
  return {
    type: CompanyActionTypes.SAVE_COMPANY_SUCCESS_ACTION,
  };
}

/**
 * Retrieves the selected company from the local storage
 */
export interface LoadCompanyFromLocalStorageAction extends Action {
  type: CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE;
}

export function loadCompanyFromLocalStorage(): LoadCompanyFromLocalStorageAction {
  return {
    type: CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE,
  };
}

/**
 * If a company was successfully loaded from the local storage, set this company as the
 * selected company
 */
export interface LoadCompanyFromLocalStorageSuccessAction extends Action {
  type: CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE_SUCCESS;
  payload: {
    company: Company;
  };
}

export function loadCompanyFromLocalStorageSuccess(company: Company): LoadCompanyFromLocalStorageSuccessAction {
  return {
    type: CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE_SUCCESS,
    payload: { company },
  };
}

export interface LoadCompanyFromLocalStorageFailAction extends Action {
  type: CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE_FAIL;
  payload: {
    error: Error;
  };
}

export function loadCompanyFromLocalStorageFail(error: Error): LoadCompanyFromLocalStorageFailAction {
  return {
    type: CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE_FAIL,
    payload: { error },
  };
}

export type CompanyActions = GetCompaniesAction |
                             GetCompaniesSuccessAction |
                             GetCompaniesFailAction |
                             SelectCompanyAction |
                             LoadCompanyFromLocalStorageAction |
                             LoadCompanyFromLocalStorageSuccessAction |
                             LoadCompanyFromLocalStorageFailAction;
