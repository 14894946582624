import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { sendTelephoneNumber } from '../../actions/sgm.action';
import SGM from '../../components/sgm/sgm';
import { State } from '../../reducers/store';

const mapStateToProps = (state: State) => ({
  telephone: state.sgmState.formattedTelephone,
  message: state.sgmState.message,
  sendStatus: state.sgmState.status,
});

const SGMContainer = connect(mapStateToProps)(SGM);
export default SGMContainer;
