import './company-selector.scss';

import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import { Company } from '../../common/company.model';

export interface CompanySelectorProps {
  companies: Company[];
  onSelectCompany: (comp: Company) => void;
}

export interface CompanySelectorState {
  selectedCompany: Company;
  selectedCompanyIndex: number;
  redirect: boolean;
}

export default class CompanySelector extends React.Component<CompanySelectorProps, CompanySelectorState> {
  constructor(props: CompanySelectorProps) {
    super(props);

    this.state = {
      selectedCompany: new Company(),
      selectedCompanyIndex: 0,
      redirect: false,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public render() {
    if (this.state.redirect) {
      return (<Redirect to='/' />);
    }

    return (
      <form className='company-selector-form d-flex flex-column' onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for='companySelector'>Companies:</Label>
          <Input
            type='select'
            name='companySelector'
            className='company-selector'
            value={this.state.selectedCompanyIndex}
            onChange={this.handleSelectChange}
            required={true}
          >
            {this.props.companies.map((comp: Company, index: number) => <option key={comp.numericalId} value={index}>{comp.name}</option>)}
          </Input>
        </FormGroup>

        <div className='d-flex justify-content-between'>
          <div>
            <Link to='/'>
              <Button className='onyo-button'>Voltar</Button>
            </Link>
          </div>
          <div>
            <Button className='onyo-button' type='submit'>Selecionar</Button>
          </div>
        </div>
      </form>
    );
  }

  private handleSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Get the array index that was stored as a value.
    const newState: CompanySelectorState = {
      selectedCompany: this.props.companies[event.target.value],
      selectedCompanyIndex: Number(event.target.value),
      redirect: false,
    };
    this.setState(newState);
  }

  private handleSubmit(event: any) {
    event.preventDefault();
    const company = this.props.companies[event.target.companySelector.value];
    this.props.onSelectCompany(company); // execute action
    this.setState({ ...this.state, redirect: true }); // redirect to root
  }
}
