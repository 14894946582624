import './keyboard-key.scss';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from 'reactstrap';

export interface KeyboardKeyProps {
  value: string;
  icon?: IconDefinition;
  onKeyPress: (value: string) => void;
}

export class KeyboardKey extends React.Component<KeyboardKeyProps> {

  constructor(props: KeyboardKeyProps) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  public render() {
    return (
      <div className='keyboard-key'>
        <Button className='key-button' size='lg' onClick={this.onButtonClick}>
          {this.props.value}
          {this.showIcon(this.props.icon)}
        </Button>
      </div>
    );
  }

  private showIcon(icon?: IconDefinition) {
    if (icon) {
      return (<FontAwesomeIcon className='key-icon' icon={icon} />);
    }
  }

  private onButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.props.onKeyPress(this.props.value);
  }
}
