import { Action } from 'redux';

export enum SGMActionTypes {
  SEND_TELEPHONE_NUMBER_ACTION = 'send-telephone-number',
  SEND_TELEPHONE_NUMBER_SUCCESS_ACTION = 'send-telephone-number-success',
  SEND_TELEPHONE_NUMBER_FAIL_ACTION = 'send-telephone-number-fail',
  RESET_TELEPHONE_FORM_ACTION = 'reset-telephone-form',
  TELEPHONE_APPEND_ACTION = 'telephone-append',
  TELEPHONE_BACKSPACE_ACTION = 'telephone-backspace',
}

export interface SendTelephoneNumberAction extends Action {
  type: SGMActionTypes.SEND_TELEPHONE_NUMBER_ACTION;
}

export function sendTelephoneNumber(): SendTelephoneNumberAction {
  return {
    type: SGMActionTypes.SEND_TELEPHONE_NUMBER_ACTION,
  };
}

export interface SendTelephoneNumberSuccessAction extends Action {
  type: SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION;
}

export function sendTelephoneNumberSuccess(): SendTelephoneNumberSuccessAction {
  return {
    type: SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION,
  };
}

export interface SendTelephoneNumberFailAction extends Action {
  type: SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION;
  payload: {
    error: Error;
  };
}

export function sendTelephoneNumberFail(error: Error): SendTelephoneNumberFailAction {
  return {
    type: SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION,
    payload: { error },
  };
}

export interface ResetTelephoneFormAction {
  type: SGMActionTypes.RESET_TELEPHONE_FORM_ACTION;
}

export function resetTelephoneForm(): ResetTelephoneFormAction {
  return {
    type: SGMActionTypes.RESET_TELEPHONE_FORM_ACTION,
  };
}

export interface TelephoneAppendAction {
  type: SGMActionTypes.TELEPHONE_APPEND_ACTION;
  payload: {
    num: string;
   };
}

export function telephoneAppend(num: string): TelephoneAppendAction {
  return {
    type: SGMActionTypes.TELEPHONE_APPEND_ACTION,
    payload: { num },
  };
}

export interface TelephoneBackspaceAction {
  type: SGMActionTypes.TELEPHONE_BACKSPACE_ACTION;
}

export function telephoneBackspace(): TelephoneBackspaceAction {
  return {
    type: SGMActionTypes.TELEPHONE_BACKSPACE_ACTION,
  };
}

export type SGMActions = SendTelephoneNumberAction |
                         SendTelephoneNumberSuccessAction |
                         SendTelephoneNumberFailAction |
                         ResetTelephoneFormAction |
                         TelephoneAppendAction |
                         TelephoneBackspaceAction;
