// tslint:disable-next-line:no-submodule-imports
import { AjaxError } from 'rxjs/ajax';

import { LoginActions, LoginActionTypes } from '../../actions/login.action';
import {
  REQ_STATUS_FAIL,
  REQ_STATUS_PROCESSING,
  REQ_STATUS_SUCCESS,
  REQ_STATUS_UNDEFINED,
} from '../../common/request-status';

export interface LoginState {
  username: string;
  password: string;
  loginToken: string;
  loginError?: Error;
  errorMessage: string;
  status: number;
}

export const initialState: LoginState = {
  username: '',
  password: '',
  loginToken: '',
  loginError: undefined,
  errorMessage: '',
  status: REQ_STATUS_UNDEFINED,
};

export function loginReducer(state: LoginState = initialState, action: LoginActions): LoginState {
  switch (action.type) {
    case LoginActionTypes.LOGIN_ACTION:
      return { ...state, username: action.payload.username, password: action.payload.password, status: REQ_STATUS_PROCESSING };

    case LoginActionTypes.LOGIN_SUCCESS_ACTION:
      return { ...state, loginToken: action.payload.loginToken, status: REQ_STATUS_SUCCESS };

    case LoginActionTypes.LOGIN_FAILED_ACTION:
      const errorMessage = handleErrorMessage(action.payload.error);
      return { ...state, loginToken: '', loginError: action.payload.error, errorMessage, status: REQ_STATUS_FAIL };

    default:
      return state;
  }
}

/**
 * Display a friendlier error message.
 */
function handleErrorMessage(error: Error): string {
  let errorMessage = 'Erro ao realizar o login, por favor tente novamente mais tarde.';

  if (error.name === 'AjaxError') {
    const ajaxError = error as AjaxError;
    if (ajaxError.response.error === 'onyo.user.invalid-data') {
      errorMessage = 'Nome de usuário ou senha incorretos';
    }
  }
  else if (error.name === 'TimeoutError') {
    errorMessage = 'Tempo limite atingido, por favor tente novamente';
  }

  return errorMessage;
}
