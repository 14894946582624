import * as React from 'react';

import SGMContainer from '../../containers/sgm-container/sgm-container';
import ErrorBoundary from '../error-boundary/error-boundary';

export function SGMErrorBoundary() {
    return (
      <ErrorBoundary
        // tslint:disable-next-line:max-line-length
        errorMessage='Erro ao carregar o componente do SGM. Por favor entre em contato com o nosso suporte no telefone: 3003-0548 ou pelo e-mail: ajuda@onyo.com.'
      >
        <SGMContainer />
      </ErrorBoundary>
    );
}
