import './login-form.scss';

import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';

interface LoginFormProps {
  onLoginUser: (username: string, password: string) => void;
}

export default class LoginForm extends React.PureComponent<LoginFormProps> {
  constructor(props: LoginFormProps) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    return (
      <form className='login-form' onSubmit={this.onSubmit}>
        <FormGroup>
          <Label for='email'>E-mail</Label>
          <Input
            id='email-input'
            type='email'
            name='email'
            className='form-input'
            required={true}
          />
        </FormGroup>
        <FormGroup>
          <Label for='password'>Senha</Label>
          <Input
            id='password-input'
            type='password'
            name='password'
            className='form-input'
            required={true}
          />
        </FormGroup>
        <div className='d-flex justify-content-between'>
          <div>
            <Link to='/'>
            <Button className='onyo-button'>Voltar</Button>
            </Link>
          </div>
          <div>
            <Button className='onyo-button' type='submit'>Login</Button>
          </div>
        </div>
      </form>
    );
  }

  private onSubmit(e) {
    e.preventDefault();
    this.props.onLoginUser(e.target.email.value, e.target.password.value);
  }
}
