import './config-error.scss';

import * as React from 'react';
import { Alert } from 'reactstrap';

export interface ConfigError {
  errorMessage: string;
}

export function ConfigError(props: ConfigError) {
    return (
      <div className='config-error text-center pt-2 pb-2'>
        <span className='text-danger'>{props.errorMessage}</span>
      </div>
    );
}
