import { connect } from 'react-redux';

import LoginView, { LoginViewProps } from '../../components/login-view/login-view';
import { State } from '../../reducers/store';

const mapStateToProps = (state: State): LoginViewProps => ({
  loginRequestStatus: state.loginState.status,
  loginErrorMessage: state.loginState.errorMessage,
  companyRequestStatus: state.companyState.status,
  companyErrorMessage: state.companyState.errorMessage,
  companyName: state.companyState.selectedCompany.name,
});

const LoginContainer = connect(mapStateToProps)(LoginView);
export default LoginContainer;
