// tslint:disable:no-submodule-imports
import * as Sentry from '@sentry/browser';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';

import {
  CompanyActionTypes,
  GetCompaniesAction,
  getCompaniesFail,
  getCompaniesSuccess,
  saveCompanySuccess,
} from '../../actions/company.action';
import { genericError } from '../../actions/error.action';
import {
  LoadCompanyFromLocalStorageAction,
  loadCompanyFromLocalStorageFail,
  loadCompanyFromLocalStorageSuccess,
  SelectCompanyAction,
} from './../../actions/company.action';
import { Company } from './../../common/company.model';

const url = `${process.env.REACT_APP_API_URL}/v1/company`;
const timeoutValue = Number(process.env.REACT_APP_DEFAULT_TIMEOUT);

/**
 * Retrieves a list of all companies from the backend.
 */
export const companyEpic = (action$, store$, { ajax, scheduler }) =>
  action$.pipe(
    ofType<GetCompaniesAction>(CompanyActionTypes.GET_COMPANIES_ACTION),
    switchMap((action: GetCompaniesAction) =>
      ajax({
        url: `${url}?_layout=simple&page_size=10000`,
        method: 'GET',
        headers: { Authorization: store$.value.loginState.loginToken },
      })
      .pipe(
        timeout(timeoutValue, scheduler),
        map((res: AjaxResponse) => getCompaniesSuccess(res.response.data)),
        catchError((error: Error) => {
          const errorMsg = 'Error getting companies from the backend';
          Sentry.captureEvent({
            message: 'Error getting companies from the backend',
            level: Sentry.Severity.Warning,
            extra: {
              error,
            },
          });
          console.warn(errorMsg, error);
          return of(getCompaniesFail(error));
        }),
      ),
    ),
  );

/**
 * Saves the selected company into the local storage.
 */
export const saveCompanyEpic = (action$, store$, { ajax, scheduler, lStorage }) =>
 action$.pipe(
  ofType<SelectCompanyAction>(CompanyActionTypes.SELECT_COMPANY_ACTION),
  map((action: SelectCompanyAction) => {
    configureSentryScope(action.payload.company);
    lStorage.setItem('selectedCompany', JSON.stringify(action.payload.company));
    return saveCompanySuccess();
  }),
  catchError((error: Error) => {
    console.error(error);
    Sentry.captureException(error);
    return of(genericError(error));
  }),
 );

 /**
  * Loads a company from the local storage
  */
export const loadCompanyFromLocalStorageEpic = (action$, store$, { ajax, scheduler, lStorage }) =>
  action$.pipe(
    ofType<LoadCompanyFromLocalStorageAction>(CompanyActionTypes.LOAD_COMPANY_FROM_LOCAL_STORAGE),
    map(() => {
      const comp = lStorage.getItem('selectedCompany');
      if (comp) {
        const parsedCompany: Company = JSON.parse(comp);
        configureSentryScope(parsedCompany);
        return loadCompanyFromLocalStorageSuccess(parsedCompany);
      }
      else {
        const error = new Error('No company in local storage');
        error.name = 'NoCompanyInLocalStorage';
        return loadCompanyFromLocalStorageFail(error);
      }
    }),
    catchError((error: Error) => {
      console.error(error);
      Sentry.captureException(error);
      return of(genericError(error));
    }),
  );

function configureSentryScope(company: Company) {
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setUser({ id: company.numericalId.toString(), username: company.name });
  });
}
