// tslint:disable:no-submodule-imports
import * as Sentry from '@sentry/browser';
import { ActionsObservable, ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, timeout } from 'rxjs/operators';

import { genericError } from '../../actions/error.action';
import { SendTelephoneNumberAction, sendTelephoneNumberFail, sendTelephoneNumberSuccess } from '../../actions/sgm.action';
import {
  resetTelephoneForm,
  SendTelephoneNumberFailAction,
  SendTelephoneNumberSuccessAction,
  SGMActionTypes,
} from './../../actions/sgm.action';

const url = `${process.env.REACT_APP_API_URL}/v1/connector/sms_temporary_user`;
const timeoutValue = Number(process.env.REACT_APP_DEFAULT_TIMEOUT);
const formResetTimeout = Number(process.env.REACT_APP_FORM_RESET_TIMEOUT);

/**
 * Try to send the telephone number or throw an error if no company is selected
 */
export const sendTelephoneEpic = (action$: ActionsObservable<any>, store$, { ajax, scheduler }) =>
  merge(
    sendTelephoneEpicInvalidNumber(action$, store$, { ajax, scheduler }),
    sendTelephoneEpicWithToken(action$, store$, { ajax, scheduler }),
    sendTelephoneEpicWithoutToken(action$, store$, { ajax, scheduler }),
  );

  /**
   * Throw an error if the telephone number is invalid (less or more than 11 digits)
   */
const sendTelephoneEpicInvalidNumber = (action$: ActionsObservable<any>, store$, { ajax, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberAction>(SGMActionTypes.SEND_TELEPHONE_NUMBER_ACTION),
    filter(() => store$.value.sgmState.telephone.length !== 11),
    map(() => {
      const errorMsg = `Invalid telephone number: ${store$.value.sgmState.telephone}`;
      const error = new Error(errorMsg);
      error.name = 'invalid-telephone-number';
      Sentry.captureEvent({
        message: errorMsg,
        level: Sentry.Severity.Warning,
        extra: {
          error,
        },
      });

      console.warn(error);
      return sendTelephoneNumberFail(error);
    }),
  );

/**
 * Throw an error if we don't have a token selected.
 */
const sendTelephoneEpicWithoutToken = (action$: ActionsObservable<any>, store$, { ajax, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberAction>(SGMActionTypes.SEND_TELEPHONE_NUMBER_ACTION),
    filter(() =>
      store$.value.sgmState.telephone.length === 11 &&
      (!store$.value.companyState.selectedCompany || store$.value.companyState.selectedCompany.posToken === '')
    ),
    map(() => {
      const errorMsg = 'No company selected';
      const error = new Error(errorMsg);
      error.name = 'no-company-selected';
      Sentry.captureEvent({
        message: errorMsg,
        level: Sentry.Severity.Warning,
        extra: {
          error,
        },
      });

      console.warn(error);
      return sendTelephoneNumberFail(error);
    }),
  );

/**
 * Try to send the telephone number if we have a company and a token selected.
 */
const sendTelephoneEpicWithToken = (action$: ActionsObservable<any>, store$, { ajax, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberAction>(SGMActionTypes.SEND_TELEPHONE_NUMBER_ACTION),
    filter(() =>
      store$.value.sgmState.telephone.length === 11 &&
      store$.value.companyState.selectedCompany &&
      store$.value.companyState.selectedCompany.posToken !== ''
    ),
    switchMap((action: SendTelephoneNumberAction) =>
      ajax({
        url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': store$.value.companyState.selectedCompany.posToken,
        },
        body: { telephone: store$.value.sgmState.telephone },
      }).pipe(
        timeout(timeoutValue, scheduler),
        map(() => sendTelephoneNumberSuccess()),
        catchError((error: Error) => {
          const errorMsg = 'Error sending telephone number';
          Sentry.captureEvent({
            message: errorMsg,
            level: Sentry.Severity.Error,
            extra: {
              error,
            },
          });

          console.error(errorMsg, error);
          return of(sendTelephoneNumberFail(error));
        }),
      ),
    ),
  );

/**
 * Emit a form reset action a few seconds after the success or failed action was emmited.
 */
export const resetTelephoneFormEpic = (action$: ActionsObservable<any>, store$, { ajax, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberSuccessAction | SendTelephoneNumberFailAction>(
      SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION,
      SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION,
    ),
    switchMap(() =>
      of(resetTelephoneForm())
      .pipe(
        delay(formResetTimeout, scheduler),
        catchError((error) => {
          Sentry.captureException(error);
          console.error(error);
          return of(genericError(error));
        }),
      ),
    ),
  );
